import React, { useState, useEffect } from "react";
import Left_logo from "../Assets/Logo_Left.png";
import Right_logo from "../Assets/Logo_RIght.png";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function Feedback({ db }) {
  const [inputValue, setInputValue] = useState("");
  const [padding, setPadding] = useState(0);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (inputValue.length < 60) setPadding(inputValue.length * 5);
    console.log(inputValue.length);
  };

  const handleSend = async () => {
    try {
      const docRef = await addDoc(collection(db, "feedback"), {
        text: inputValue,
        isPublic: false,
      });
      navigate("/");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <section className="feedback">
      <h1 className="text-xl">
        Thank you for ordering from <span>CLEMENTINE MARKET</span>!
      </h1>
      <div>
        <img src={Left_logo} alt="" width={100} />
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          style={{ width: `${150 + padding}px` }}
          placeholder="Type..."
        />
        <img src={Right_logo} alt="" width={100} />
      </div>

      <button onClick={handleSend}>PRINT</button>
    </section>
  );
}
