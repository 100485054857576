import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./Routes/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Feedback from "./Routes/Feedback";

import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

function App() {
  const [data, setData] = useState([]);
  console.log(process.env.REACT_APP_APIKEY);
  const fetchPost = async () => {
    await getDocs(collection(db, "feedback")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setData(newData);
      console.log(data, newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home data={data} />} />
        <Route path="/feedback" element={<Feedback db={db} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
