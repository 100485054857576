import React from "react";
import { motion } from "framer-motion";

import Left_logo from "../Assets/Logo_Left.png";
import Right_logo from "../Assets/Logo_RIght.png";

export default function Home({ data }) {
  function splitArrayInHalf(data) {
    data = shuffle(data);
    const middleIndex = Math.ceil(data.length / 2);
    const firstHalf = data.slice(0, middleIndex);
    const secondHalf = data.slice(middleIndex);
    return { firstHalf, secondHalf };
  }

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const { firstHalf, secondHalf } = splitArrayInHalf(data);
  return (
    <section className="home w-full">
      <marquee direction="left" loop="3">
        <div className="feedback">
          {firstHalf.map((feed) => {
            if (feed.isPublic)
              return (
                <motion.div
                  initial={{ opacity: 0, scale: 0, y: [-10, 10] }}
                  whileInView={{
                    opacity: 1,
                    scale: Math.random() * (1.2 - 1) + 0.8,
                  }}
                  transition={{ duration: 2, ease: "easeInOut" }}
                >
                  <img src={Left_logo} alt="" width={100} />
                  <h3>{feed.text}</h3>
                  <img src={Right_logo} alt="" width={100} />
                </motion.div>
              );
          })}
        </div>
      </marquee>
      <div>
        <h1 className="text-7xl lg:text-9xl font-black">
          CLEMEN
          <br />
          TINE
        </h1>
        <h2>Kuwait City</h2>
      </div>
      <marquee direction="right" loop="3">
        <div className="feedback">
          {secondHalf.map((feed) => {
            if (feed.isPublic)
              return (
                <motion.div
                  initial={{ opacity: 0, scale: 0, y: [-10, 10] }}
                  whileInView={{
                    opacity: 1,
                    scale: Math.random() * (1.2 - 1) + 0.8,
                  }}
                >
                  <img src={Left_logo} alt="" width={100} />
                  <h3>{feed.text}</h3>
                  <img src={Right_logo} alt="" width={100} />
                </motion.div>
              );
          })}
        </div>
      </marquee>
    </section>
  );
}
